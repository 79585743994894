import {
  Button,
  Dialog,
  DialogActions,
  Grow,
  createStyles,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction='down' timeout={1000} ref={ref} {...props} />;
});

const RequestModal = (props) => {
  const { isModalVisible, name = "", handleAccept, handleReject, toVideoRequestType } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isModalVisible}
      // onClose={onClose}
      aria-labelledby='draggable-dialog-title'
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
      fullWidth={true}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
      TransitionComponent={Transition}
    >
      {/* <DialogTitle
        style={{ cursor: "move", color: "#fff", fontSize: "24px" }}
        id="draggable-dialog-title"
      >
        Switching to Video Call
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText className={classes.title}>
          {toVideoRequestType == "initial"
            ? `${name} is requesting to switch to a video
        call`
            : `This call has been switched to video.
        Do you wish to continue?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleAccept}>
          Accept
        </Button>
        <Button className={classes.button} onClick={handleReject}>
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RequestModal;

const useStyles = makeStyles(() =>
  createStyles({
    paperScrollPaper: {
      backgroundColor: "rgba(30,30,30,0.8)",
    },
    title: {
      paddingTop: 10,
      paddingLeft: 15,
      color: "#fff",
    },
    button: {
      color: "#1E79E7",
      textTransform: "capitalize",
    },
  })
);
