const isDEV = true;
export default class Logger {
  // static log(message) {
  //   if (isDEV) console.log(TAG + message);
  // }

  // static log(message, object) {
  //   if (isDEV) console.log(TAG + message, object);
  // }

  // static log(dest, message, object) {
  //   if (isDEV) console.log(TAG + dest + destSpacing + message, object);
  // }
  static log(...params) {
    console.log("log callled");
    if (isDEV) console.log(...params);
  }
}
