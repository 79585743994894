import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grow,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography,
  Card,
  FormHelperText,
  Button,
  Grid,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import WarningIcon from "@material-ui/icons/Warning";
import { ReactComponent as PeopleOutlineIcon } from "../assets/users-icon.svg";
import { ReactComponent as SettingsOutlinedIcon } from "../assets/Settings.svg";
import { ReactComponent as ChangeLayoutIcon } from "../assets/changeLayout.svg";
import { ReactComponent as SpeakerIcon } from "../assets/Audio-setting.svg";
import { ReactComponent as FlipCameraIosIcon } from "../assets/video-setting.svg";
import iconBlurBack from "../../../icons/icon_blurBack.png";
import iconBlurBack2 from "../../../icons/icon_blurBack2.png";
import icon_virtual_background from "../../../icons/icon_virtual_background.png";

// import SpeakerIcon from "@material-ui/icons/Speaker";
// import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { backgroundButtonStyle, settingStyle } from "./BottomButtonsStyle";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import clsx from "clsx";
import MainVideoComponent from "../MainVideo";
import { addBackgroundEffect } from "../Utils/AddVirtualBackground";
import {
  toDataUrl,
  getBase64,
  setImagesInIndexBD,
  deleteImagesInIndexBD,
} from "../Utils/functions";
import { defaultBackgroundImageUrls } from "../backgoundImgUrl";
import { createLocalTracksfun } from "../../../lib/utils";
import { loadModel } from "../virtual-background";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction='down' timeout={1000} ref={ref} {...props} />;
});

const SettingsButton = ({
  showChangeModeButton,
  changeMode,
  mode,
  activeInputDevices,
  activeOutputDevices,
  changeAudioSourceClicked,
  changeVideoSourceClicked,
  changeAudioOutputDeviceClicked,
  audioOutPutDevices,
  audioDevices,
  videoDevices,
  activeVideoDevice,
  peopleClicked,
  // noOfUsersInTile,
  localizedObject,
  onBackgroundEffectSelected,
  selectedBg,
  appliedBg,
  setSelectedBg,
  db,
  AllowBackgroundBlur,
  AllowUserImages,
  ShowDefaultImages,
  isBackgroundModelVisible,
  setIsBackgroundModalVisible,
  EnforceBackgroundImage,
  EnforceBackgroundBlur,
  ShowVirtualBackgroundSetting,
  SetImages,
  isAudioOnly,
  addedBackgroundImage,
  addedBackgroundBlur,
  setNoOfUsersPerPage,
  noOfUsersPerPage,
}) => {
  const classes = settingStyle();
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const backgroundClasses = backgroundButtonStyle();
  const [isTfliteDownloaded, setIsTfliteDownloaded] = React.useState(false);

  const [localVideoTrack, setLocalVideoTrack] = React.useState({});
  const [showSetting, setShowSetting] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFullScreen, setFullScreen] = React.useState(null);
  const [openLayoutModel, setOpenLayoutModel] = React.useState(false);
  const [changeAudioDeviceModel, setChangeAudioDeviceModel] = React.useState(false);
  const [changeVideoDeviceModel, setChangeVideoDeviceModel] = React.useState(false);
  const [currentLayout, setCurrentLayout] = React.useState(mode === "DEFAULT" ? "SIDEBAR" : mode);
  const handleSettingClose = () => {
    setShowSetting(false);
  };
  const addedVirtualBackgroundImage = addedBackgroundImage ? [addedBackgroundImage] : [];
  const addedVirtualBackgroundBlur = addedBackgroundBlur
    ? [{ value: addedBackgroundBlur, type: "blur" }]
    : [];
  const enforceBackgroundImage = EnforceBackgroundImage ? [EnforceBackgroundImage] : [];
  // const [isBackgroundModelVisible, setIsBackgroundModalVisible] =
  //   React.useState(false);

  const settingRef = useRef(null);
  const speakerChangeSupported = useRef(
    JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")
  ).current;
  const inputDeviceChangeSupported = useRef(
    JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("input")
  ).current;

  /**
   * checking wheather the app is in full screen mode and setting state accordingly on componentdidmount
   */
  useEffect(() => {
    let doc = window.document;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  }, []);

  useEffect(() => {
    ShowDefaultImages &&
      defaultBackgroundImageUrls.forEach((item) => {
        toDataUrl(item, (file) => {
          setDefaultImages((prev) => [...prev, file]);
        });
      });
  }, []);
  useEffect(() => {
    db.collection("backgroundImages")
      .get()
      .then((data) => {
        if (data)
          setBackgroundImages(
            data
              ?.map((item) =>
                ShowDefaultImages
                  ? item.fileType === "custom"
                    ? {
                        type: "customImage",
                        imageUrl: item.file,
                        id: item.id,
                      }
                    : item.fileType === "default"
                    ? null
                    : item.file
                  : item.fileType === "custom"
                  ? {
                      type: "customImage",
                      imageUrl: item.file,
                      id: item.id,
                    }
                  : item.fileType === "default"
                  ? null
                  : item.file
              )
              ?.filter((item) => item)
          );
        else setBackgroundImages([]);
      });
  }, []);
  /**
   * toggleFullScreen function  toggles fullscreen mode of the app
   */
  // const toggleFullScreen = () => {
  //   let doc = window.document;
  //   let docEl = doc.documentElement;

  //   let requestFullScreen =
  //     docEl.requestFullscreen ||
  //     docEl.mozRequestFullScreen ||
  //     docEl.body.webkitRequestFullScreen ||
  //     docEl.msRequestFullscreen;
  //   let cancelFullScreen =
  //     doc.exitFullscreen ||
  //     doc.mozCancelFullScreen ||
  //     doc.webkitExitFullscreen ||
  //     doc.msExitFullscreen;

  //   if (
  //     !doc.fullscreenElement &&
  //     !doc.mozFullScreenElement &&
  //     !doc.webkitFullscreenElement &&
  //     !doc.msFullscreenElement
  //   ) {
  //     requestFullScreen.call(docEl);
  //     setFullScreen(true);
  //   } else {
  //     cancelFullScreen.call(doc);
  //     setFullScreen(false);
  //   }
  // };

  /**
   * we store the value of max number of user in grid as squareRoot
   * for ex if max users are 9 then we store 3
   * here we are converting that as percentage for slider
   * @returns {number}
   */
  // const getValue = () => {
  //   return (noOfUsersInTile - 2) * 20;
  // };
  const getNoOfUserPerPage = () => {
    return (noOfUsersPerPage - 2) * 50;
  };

  /**
   * UI dilog for select layout
   * @returns {Component}
   */
  const layoutDialog = () => {
    return (
      <Dialog
        classes={{
          scrollPaper: classes.scrollPaper,
          paperScrollPaper: classes.paperScrollPaper,
        }}
        fullWidth={true}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
        TransitionComponent={Transition}
        aria-labelledby='draggable-dialog-title'
        onClose={() => {
          setOpenLayoutModel(false);
        }}
        // aria-labelledby="simple-dialog-title"
        open={openLayoutModel}
      >
        <IconButton
          data-testid='cc-close-layout'
          className={classes.closeIcon}
          component='span'
          onClick={(e) => {
            e.stopPropagation();
            setOpenLayoutModel(false);
          }}
          size={"small"}
        >
          <CloseIcon className={classes.whiteColor} fontSize='small' />
        </IconButton>
        <div
          style={{
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.whiteColor} variant={"h4"}>
            {localizedObject["SELECT_LAYOUT_HEADER"]}
          </Typography>
        </div>
        <FormControl className={classes.layoutDilogFormControl} component='fieldset'>
          <RadioGroup
            aria-label='Layout'
            value={currentLayout}
            onChange={(value) => {
              setCurrentLayout(value.target.value);
              changeMode(value.target.value);
            }}
          >
            <FormControlLabel
              value='TILE'
              control={<Radio className={classes.whiteColor} color='primary' />}
              label='Tile Mode'
              className={classes.whiteColor}
            />
            {currentLayout === "TILE" ? (
              <>
                <Typography className={classes.whiteColor} id='discrete-slider-always' gutterBottom>
                  {localizedObject["NO_OF_USER_IN_PAGINATED_TEXT"]}
                </Typography>
                <Slider
                  classes={{
                    markLabel: classes.markLabel,
                    root: classes.PaginatedRoot,
                    markActive: classes.markActive,
                  }}
                  getAriaValueText={valueText}
                  defaultValue={getNoOfUserPerPage()}
                  onChange={(e, newValue) => {
                    setNoOfUsersPerPage(newValue / 50 + 2);
                  }}
                  aria-labelledby='discrete-slider-always'
                  step={50}
                  marks={[
                    {
                      value: 0,
                      label: "4",
                    },
                    {
                      value: 50,
                      label: "9",
                    },
                    {
                      value: 100,
                      label: "16",
                    },
                  ]}
                  color='primary'
                  style={{ marginLeft: 4 }}
                />
              </>
            ) : null}
            {/* <FormControlLabel
              value="TILE"
              control={<Radio className={classes.whiteColor} color="primary" />}
              label="Tile Mode"
              className={classes.whiteColor}
            />
            {currentLayout === "TILE" ? (
              <>
                <Typography
                  className={classes.whiteColor}
                  id="discrete-slider-always"
                  gutterBottom
                >
                  {localizedObject["NO_OF_USER_IN_TILE_TEXT"]}
                </Typography>
                <Slider
                  classes={{
                    markLabel: classes.markLabel,
                    root: classes.root,
                    markActive: classes.markActive,
                  }}
                  getAriaValueText={valueText}
                  defaultValue={getValue()}
                  onChange={(e, newValue) => {
                    setNoOfUsersInTile(newValue / 20 + 2);
                  }}
                  aria-labelledby="discrete-slider-always"
                  step={20}
                  marks={marks}
                  color="primary"
                  style={{ marginLeft: 4 }}
                />
              </>
            ) : null} */}
            <FormControlLabel
              value='SPOTLIGHT'
              control={<Radio className={classes.whiteColor} color='primary' />}
              label='Spotlight Mode'
              className={classes.whiteColor}
            />
            <FormControlLabel
              value='SIDEBAR'
              className={classes.whiteColor}
              control={<Radio className={classes.whiteColor} color='primary' />}
              label='Sidebar Mode'
            />
          </RadioGroup>
        </FormControl>
      </Dialog>
    );
  };

  /**
   * UI dilog for audio settings
   * @returns {Component}
   */
  const audioDevicesModel = () => {
    return (
      <Dialog
        open={changeAudioDeviceModel}
        onClose={() => {
          setChangeAudioDeviceModel(false);
        }}
        classes={{
          scrollPaper: classes.scrollPaper,
          paperScrollPaper: classes.paperScrollPaper,
        }}
        fullWidth={true}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
        TransitionComponent={Transition}
        aria-labelledby='form-dialog-title'
      >
        <div
          style={{
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.whiteColor} variant={"h4"}>
            {localizedObject["CHANGE_AUDIO_SOURCE"]}
          </Typography>
          <IconButton
            className={classes.closeIcon}
            component='span'
            onClick={(e) => {
              e.stopPropagation();
              setChangeAudioDeviceModel(false);
            }}
            size={"small"}
          >
            <CloseIcon className={classes.whiteColor} fontSize='small' />
          </IconButton>
        </div>

        {/* <DialogTitle id="form-dialog-title">Change Audio Source</DialogTitle> */}
        <DialogContent style={{ paddingTop: 0, paddingLeft: 16 }}>
          <DialogContentText className={classes.whiteColor}>
            {localizedObject["CHANGE_AUDIO_SOURCE_DESC"]}
          </DialogContentText>

          {inputDeviceChangeSupported && (
            <FormControl className={classes.audioDilogFormControl} variant='outlined' focused>
              <InputLabel className={classes.whiteColor} id='demo-simple-select-standard-label1'>
                {localizedObject["AUDIO_INPUT_DEVICE_LABEL"]}
              </InputLabel>
              <Select
                classes={{
                  icon: classes.whiteColor,
                }}
                MenuProps={{ classes: { paper: classes.dropDownList } }}
                disabled={!audioDevices.length}
                error={!audioDevices.length ? localizedObject["NO_AUDIO_DEVICE_AVAILABLE"] : null}
                className={classes.dropDownStyle}
                labelId='demo-simple-select-standard-label1'
                id='demo-simple-select-standard'
                data-testid={"cc-audioSettingInput"}
                value={activeInputDevices}
                label={localizedObject["AUDIO_INPUT_DEVICE_LABEL"]}
                onChange={(e) => {
                  changeAudioSourceClicked(e.target.value);
                }}
              >
                {audioDevices.map((item, i) => {
                  return (
                    <MenuItem
                      className={classes.whiteColor}
                      key={item.deviceId}
                      value={item.deviceId}
                      data-testid={`cc-audioSettingInputDeviceMenu${i}`}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {!audioDevices.length ? localizedObject["NO_AUDIO_DEVICE_AVAILABLE"] : ""}
              </FormHelperText>
            </FormControl>
          )}
          {speakerChangeSupported && (
            <FormControl className={classes.audioDilogFormControl} variant='outlined' focused>
              <InputLabel className={classes.whiteColor} id='demo-simple-select-filled-label2'>
                {localizedObject["AUDIO_OUTPUT_DEVICE_LABEL"]}
              </InputLabel>
              <Select
                classes={{
                  icon: classes.whiteColor,
                  focused: classes.whiteColor,
                }}
                MenuProps={{ classes: { paper: classes.dropDownList } }}
                className={classes.dropDownStyle}
                error={
                  !audioOutPutDevices.length ? localizedObject["NO_AUDIO_DEVICE_AVAILABLE"] : null
                }
                disabled={!audioOutPutDevices.length}
                labelId='demo-simple-select-filled-label2'
                id='demo-simple-select-filled'
                data-testid={"cc-audioSettingOutput"}
                value={activeOutputDevices}
                label={localizedObject["AUDIO_OUTPUT_DEVICE_LABEL"]}
                onChange={(e) => {
                  changeAudioOutputDeviceClicked(e.target.value);
                }}
              >
                {audioOutPutDevices.map((item, i) => {
                  return (
                    <MenuItem
                      className={classes.whiteColor}
                      key={item.deviceId}
                      value={item.deviceId}
                      data-testid={`cc-audioSettingOutputDeviceMenu${i}`}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {!audioOutPutDevices.length ? localizedObject["NO_AUDIO_DEVICE_AVAILABLE"] : ""}
              </FormHelperText>
            </FormControl>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * UI dilog for video settings
   * @returns {Component}
   */
  const videoDevicesModel = () => {
    const devicesRef = useRef({});
    const [loading, setLoading] = useState({});
    const [localVideos, setLocalVideos] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(activeVideoDevice);
    const createLocalTracks = async (newDevice) => {
      // await disposeLocalVideo();
      let track = await JitsiMeetJS.createLocalTracks({
        devices: ["video"],
        facingMode: "user",
        cameraDeviceId: newDevice ? newDevice : activeVideoDevice,
      });
      return track[0];
    };

    const disposeAllTracks = () => {
      localVideos.forEach((item) => {
        item.dispose();
        setLoading((prev) => ({
          ...prev,
          [item.deviceId]: true,
        }));
      });
    };

    useEffect(() => {
      if (changeVideoDeviceModel) {
        let promise = [];
        videoDevices.forEach((item) => {
          promise.push(createLocalTracks(item.deviceId));
          devicesRef.current = {
            ...devicesRef.current,
            [item.deviceId]: item.label,
          };
          setLoading((prev) => ({
            ...prev,
            [item.deviceId]: true,
          }));
        });

        Promise.allSettled(promise).then((t) => {
          let tracks = t
            .map((item) => (item.status === "fulfilled" ? item.value : null))
            ?.filter((item) => item);
          setLocalVideos(tracks);
        });
      }
      return disposeAllTracks();
    }, [changeVideoDeviceModel]);

    const gridItem = (item) => {
      return (
        <Grid
          item
          md={localVideos.length === 1 ? 12 : 6}
          xs={12}
          key={item.id}
          style={{
            cursor: "pointer",
            minHeight: 170,
            alignItems: "center",
            justifyContent: "center",
          }}
          className={`grid-box ${item.deviceId === selectedDevice ? "show-border" : ""}`}
          onClick={() => setSelectedDevice(item.deviceId)}
        >
          <div
            style={{
              position: "absolute",
              minHeight: 160,
              width: "95%",
              height: "94%",
              backgroundColor: loading[item.deviceId] ? "#1c2226" : "transparent",
              borderRadius: 8,
              zIndex: 99,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              color: "#fff",
              paddingBottom: 5,
            }}
          >
            <div
              style={{
                backgroundColor: "#31383e",
                padding: "1px 6px",
                borderRadius: "5px",
              }}
            >
              <p>{devicesRef.current[item.deviceId]}</p>
            </div>
          </div>
          <MainVideoComponent
            onplay={() => {
              setLoading((prev) => ({
                ...prev,
                [item.deviceId]: false,
              }));
            }}
            streamManager={item}
            type={"video"}
            style={{ maxHeight: "45vh", objectFit: "contain", borderRadius: 6 }}
            flipped
          />
        </Grid>
      );
    };

    return (
      <Dialog
        open={changeVideoDeviceModel}
        onClose={() => {
          disposeAllTracks();
          setChangeVideoDeviceModel(false);
        }}
        aria-labelledby='form-dialog-title'
        classes={{
          scrollPaper: classes.scrollPaper,
          paperScrollPaper: classes.paperScrollPaper,
        }}
        fullWidth={true}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
        TransitionComponent={Transition}
      >
        <div
          style={{
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.whiteColor} variant={"h4"}>
            {localizedObject["CHANGE_VIDEO_SOURCE"]}
          </Typography>
          <IconButton
            className={classes.closeIcon}
            component='span'
            onClick={(e) => {
              e.stopPropagation();
              disposeAllTracks();
              setChangeVideoDeviceModel(false);
            }}
            size={"small"}
          >
            <CloseIcon className={classes.whiteColor} fontSize='small' />
          </IconButton>
        </div>
        <DialogContent style={{ paddingTop: 0, paddingLeft: 16, minHeight: 200 }}>
          <DialogContentText className={classes.whiteColor}>
            {localizedObject["CHANGE_VIDEO_SOURCE_DESC"]}
          </DialogContentText>
          <Grid container spacing={2} data-testid={"cc-videoDeviceContainer"}>
            {localVideos.map((item) => {
              // if (i==0) return null
              return <>{gridItem(item)}</>;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.whiteColor}
            style={{ textTransform: "none", outline: "none" }}
            onClick={() => {
              disposeAllTracks();
              setChangeVideoDeviceModel(false);
            }}
          >
            Cancel
          </Button>
          <Button
            id={`vbApplyButton`}
            color='primary'
            style={{ textTransform: "none", outline: "none" }}
            autoFocus
            onClick={() => {
              disposeAllTracks();
              changeVideoSourceClicked(selectedDevice);
              setChangeVideoDeviceModel(false);
            }}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onBackgroundEffectSelectedPreview = (option) => {
    addBackgroundEffect({
      track: localVideoTrack,
      option,
    });

    setSelectedOptions(option);
    setSelectedBg(option.selectedIndex);
  };

  const virtualBackgroundClicked = () => {
    setIsBackgroundModalVisible(true);
  };

  const onLocalTracks = (tracks) => {
    try {
      tracks.forEach(async (_, i) => {
        if (tracks[i].getType() === "video") {
          if (appliedBg !== 0) {
            await addBackgroundEffect({
              track: tracks[i],
              option:
                addedVirtualBackgroundImage.length === 1
                  ? { type: "img", url: addedVirtualBackgroundImage[0] }
                  : addedVirtualBackgroundBlur.length === 1
                  ? {
                      type: "blur",
                      blurValue: addedVirtualBackgroundBlur[0].value,
                    }
                  : EnforceBackgroundImage !== "" && typeof EnforceBackgroundImage === "string"
                  ? { type: "img", url: EnforceBackgroundImage }
                  : EnforceBackgroundBlur === 1 || EnforceBackgroundBlur === 2
                  ? {
                      type: "blur",
                      blurValue: EnforceBackgroundBlur === 1 ? 6 : 15,
                    }
                  : selectedOptions,
            });
          }
          setLocalVideoTrack(tracks[i]);
        }
      });
    } catch (error) {
      console.log("[onLocalTracks]", error);
    }
  };

  const disposeLocalVideo = async () => {
    if (localVideoTrack.dispose) {
      await localVideoTrack.dispose();
    }
  };
  const cancelvirtualBackgroundModel = () => {
    setSelectedBg(appliedBg);
    setIsBackgroundModalVisible(false);
    disposeLocalVideo();
  };

  const deleteCustomImage = (id, i) => {
    deleteImagesInIndexBD(db, id);
    setBackgroundImages((prev) => prev.filter((item) => item.id !== id));
    if (selectedBg !== 0 && i < selectedBg) {
      let index =
        [
          ...defaultImages,
          ...SetImages,
          ...enforceBackgroundImage,
          ...addedVirtualBackgroundImage,
          ...addedVirtualBackgroundBlur,
        ].filter((item) => item !== "" || typeof item === "string").length + 3;
      console.log("index", index, i);
      if (index <= i) {
        console.log("in if");
        setSelectedBg(selectedBg - 1);
      }
    }
    console.log(selectedBg, "appliedBg", appliedBg);
    if (i === appliedBg) {
      onBackgroundEffectSelectedPreview({
        type: "none",
        selectedIndex: 0,
      });
      onBackgroundEffectSelected({
        type: "none",
        selectedIndex: 0,
      });
    } else if (i === selectedBg) {
      onBackgroundEffectSelectedPreview({
        type: "none",
        selectedIndex: 0,
      });
    }
  };

  const virtualBackgroundModel = () => {
    useEffect(() => {
      if (isBackgroundModelVisible) {
        createLocalTracksfun({
          devices: ["video"],
          facingMode: "user",
        }).then((t) => {
          onLocalTracks(t);
        });

        if (!isTfliteDownloaded) {
          loadModel().then(() => {
            setIsTfliteDownloaded(true);
          });
        }
      }
      return () => {
        disposeLocalVideo();
      };
    }, [isBackgroundModelVisible]);

    return (
      <Dialog
        data-testid='cc-virtual-bg-dialog'
        open={isBackgroundModelVisible}
        onClose={cancelvirtualBackgroundModel}
        maxWidth='sm'
        aria-labelledby='form-dialog-title'
        classes={{
          scrollPaper: backgroundClasses.scrollPaper,
          paperScrollPaper: backgroundClasses.paperScrollPaper,
        }}
        fullWidth={true}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.8)" } }}
        TransitionComponent={Transition}
      >
        <MainVideoComponent
          streamManager={localVideoTrack}
          type={"video"}
          style={{ maxHeight: "45vh", objectFit: "contain" }}
          flipped
        />
        <div className={backgroundClasses.upperContainer} style={{ paddingBottom: 10 }}>
          <Typography
            className={backgroundClasses.whiteColor}
            variant={isTfliteDownloaded ? "h4" : "p"}
          >
            {isTfliteDownloaded ? "Choose Background" : "Downloading required files..."}
          </Typography>
          {AllowUserImages && isTfliteDownloaded && (
            <>
              <input
                accept='image/png, image/jpeg'
                style={{ display: "none" }}
                id='contained-button-file'
                type='file'
                onChange={async (e) => {
                  if (!e.target.files || (e.target.files && !e.target.files[0])) return;
                  let file = await getBase64(e.target.files[0]);

                  let id = setImagesInIndexBD(db, file, "custom", backgroundImages.length);
                  console.log("custom id", id);
                  setBackgroundImages((prev) => [
                    ...prev,
                    {
                      type: "customImage",
                      imageUrl: file,
                      id,
                    },
                  ]);
                  let selectedIndex =
                    [
                      ...defaultImages,
                      ...SetImages,
                      ...enforceBackgroundImage,
                      ...addedVirtualBackgroundImage,
                      ...addedVirtualBackgroundBlur,
                      ...backgroundImages,
                    ].filter((item) => item !== "" || typeof item === "string").length + 3;
                  onBackgroundEffectSelectedPreview({
                    type: "img",
                    url: file,
                    selectedIndex,
                  });
                }}
              />
              <label htmlFor='contained-button-file'>
                <Button color='primary' style={{ textTransform: "none" }} component='span'>
                  Add <AddCircleIcon style={{ marginLeft: 5 }} />
                </Button>
              </label>
            </>
          )}
          <IconButton
            className={backgroundClasses.closeIcon}
            component='span'
            onClick={(e) => {
              e.stopPropagation();
              cancelvirtualBackgroundModel();
            }}
            size={"small"}
          >
            <CloseIcon className={backgroundClasses.whiteColor} fontSize='small' />
          </IconButton>
        </div>
        {isTfliteDownloaded && (
          <>
            <DialogContent className={backgroundClasses.dilogContent}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                  <Button
                    id={`vbImageButton0`}
                    className={backgroundClasses.buttonContainer}
                    onClick={() => {
                      onBackgroundEffectSelectedPreview({
                        type: "none",
                        selectedIndex: 0,
                      });
                    }}
                  >
                    <div
                      className={clsx(
                        backgroundClasses.noneContainer,
                        selectedBg === 0 ? backgroundClasses.active : {}
                      )}
                    >
                      <Typography className={backgroundClasses.noneFont} variant='body1'>
                        None
                      </Typography>
                    </div>
                  </Button>
                </Grid>
                {AllowBackgroundBlur && (
                  <>
                    <Grid item md={4} xs={6}>
                      <Button
                        id={`vbImageButton1`}
                        className={backgroundClasses.buttonContainer}
                        onClick={() => {
                          onBackgroundEffectSelectedPreview({
                            type: "blur",
                            blurValue: 6,
                            selectedIndex: 1,
                          });
                        }}
                      >
                        <div
                          className={clsx(
                            backgroundClasses.blurContainer,
                            selectedBg === 1 ? backgroundClasses.active : {}
                          )}
                        >
                          <img
                            src={iconBlurBack2}
                            data-testid='cc-vb-blur-low'
                            alt=''
                            className={backgroundClasses.image}
                            style={{
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Button>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Button
                        id={`vbImageButton2`}
                        data-testid='cc-vb-blur-high'
                        className={backgroundClasses.buttonContainer}
                        onClick={() => {
                          onBackgroundEffectSelectedPreview({
                            type: "blur",
                            blurValue: 15,
                            selectedIndex: 2,
                          });
                        }}
                      >
                        <div
                          className={clsx(
                            backgroundClasses.blurContainer,
                            selectedBg === 2 ? backgroundClasses.active : {}
                          )}
                        >
                          <img
                            src={iconBlurBack}
                            alt=''
                            className={backgroundClasses.image}
                            style={{
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Button>
                    </Grid>
                  </>
                )}
                {[
                  ...defaultImages,
                  ...SetImages,
                  ...enforceBackgroundImage,
                  ...addedVirtualBackgroundImage,
                  ...addedVirtualBackgroundBlur,
                  ...backgroundImages,
                ].map((imageUrl, i) => {
                  if (
                    imageUrl === "" ||
                    (typeof imageUrl !== "string" && imageUrl.type !== "customImage")
                  ) {
                    if (imageUrl?.type === "blur") {
                      return (
                        <Grid item md={4} key={i} xs={6}>
                          <Button
                            id={`vbImageButton${i + 3}`}
                            className={backgroundClasses.buttonContainer}
                            onClick={() => {
                              onBackgroundEffectSelectedPreview({
                                type: "blur",
                                blurValue: imageUrl?.value,
                                selectedIndex: i + 3,
                              });
                            }}
                          >
                            <div
                              className={clsx(
                                backgroundClasses.blurContainer,
                                selectedBg === i + 3 ? backgroundClasses.active : {}
                              )}
                            >
                              <img
                                src={iconBlurBack}
                                alt=''
                                className={backgroundClasses.image}
                                style={{
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Button>
                        </Grid>
                      );
                    } else return;
                  }
                  return (
                    <Grid item md={4} key={i} xs={6}>
                      <div style={{ position: "relative" }}>
                        {typeof imageUrl !== "string" && imageUrl.type === "customImage" && (
                          <IconButton
                            style={{
                              backgroundColor: "#fff",
                              position: "absolute",
                              top: -10,
                              right: 0,
                              cursor: "pointer",
                              padding: 0,
                              zIndex: 1,
                            }}
                            onClick={() => {
                              deleteCustomImage(imageUrl.id, i + 3);
                            }}
                          >
                            <RemoveCircleIcon style={{ color: "red", fontSize: 20 }} />
                          </IconButton>
                        )}
                        <Button
                          id={`vbImageButton${i + 3}`}
                          className={backgroundClasses.buttonContainer}
                          onClick={() => {
                            onBackgroundEffectSelectedPreview({
                              type: "img",
                              url:
                                typeof imageUrl !== "string" && imageUrl.type === "customImage"
                                  ? imageUrl.imageUrl
                                  : imageUrl,
                              selectedIndex: i + 3,
                            });
                          }}
                        >
                          <div
                            className={clsx(
                              backgroundClasses.imgContainer,
                              selectedBg === i + 3 ? backgroundClasses.active : {}
                            )}
                          >
                            <img
                              src={
                                typeof imageUrl !== "string" && imageUrl.type === "customImage"
                                  ? imageUrl.imageUrl
                                  : imageUrl
                              }
                              alt=''
                              className={backgroundClasses.image}
                            />
                          </div>
                        </Button>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.whiteColor}
                style={{ textTransform: "none" }}
                onClick={cancelvirtualBackgroundModel}
              >
                Cancel
              </Button>
              <Button
                id={`vbApplyButton`}
                color='primary'
                style={{ textTransform: "none" }}
                autoFocus
                onClick={() => {
                  console.log("selectedBackgroundOptions", selectedOptions);
                  onBackgroundEffectSelected(selectedOptions);
                  setIsBackgroundModalVisible(false);
                  disposeLocalVideo();
                }}
              >
                Apply
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  /**
   * converts the number of user stored in squareroot to actual number that user need to see
   * @returns {string}
   */
  const valueText = (value) => {
    let number = ((value % 20) + 1) * 8;
    return number.toString();
  };

  return (
    <div>
      <IconButton
        id={`settingButton`}
        data-testid='cc-setting'
        ref={settingRef}
        component='span'
        className={classes.iconButtonStyle}
        onClick={(e) => {
          e.stopPropagation();
          setShowSetting(!showSetting);
        }}
      >
        <SettingsOutlinedIcon
          aria-controls='simple-menu'
          aria-haspopup='true'
          fontSize='medium'
          className={classes.iconColor}
        />
      </IconButton>
      <Popper
        data-testid='cc-setting-dialog'
        style={{ zIndex: 10000 }}
        open={showSetting}
        anchorEl={settingRef.current}
        role={undefined}
        transition
        disablePortal
        placement={"top-end"}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left-end",
            }}
          >
            <Card style={{ backgroundColor: "#1e1e1e" }}>
              <ClickAwayListener onClickAway={handleSettingClose}>
                <MenuList
                  autoFocusItem={showSetting}
                  id='menu-list-grow'
                  onKeyDown={handleSettingClose}
                >
                  {/* {showFullScreen ? (
                    <MenuItem
                      onClick={() => {
                        toggleFullScreen();
                        handleSettingClose();
                      }}
                    >
                      <div className={classes.menuIconContainer}>
                        {isFullScreen ? (
                          <FullscreenExitIcon
                            style={{ color: "#fff" }}
                            fontSize="medium"
                          />
                        ) : (
                          <FullscreenIcon
                            style={{ color: "#fff" }}
                            fontSize="medium"
                          />
                        )}
                      </div>
                      <Typography style={{ color: "#fff" }} variant="inherit">
                        {isFullScreen
                          ? localizedObject["EXIT_FULL_SCREEN"]
                          : localizedObject["FULL_SCREEN"]}
                      </Typography>
                    </MenuItem>
                  ) : null} */}
                  <Hidden only='xs'>
                    {showChangeModeButton ? (
                      <MenuItem
                        onClick={() => {
                          setOpenLayoutModel(true);
                          handleSettingClose();
                        }}
                      >
                        <div className={classes.menuIconContainer}>
                          <ChangeLayoutIcon className={classes.iconColor} fontSize='medium' />
                        </div>
                        <Typography style={{ color: "#fff" }} variant='inherit'>
                          {localizedObject["CHANGE_LAYOUT"]}
                        </Typography>
                      </MenuItem>
                    ) : null}
                  </Hidden>
                  <MenuItem
                    data-testid={"cc-audioSettingButton"}
                    onClick={() => {
                      setChangeAudioDeviceModel(true);
                      handleSettingClose();
                    }}
                  >
                    <div className={classes.menuIconContainer}>
                      <SpeakerIcon className={classes.iconColor} stroke='#fff' />
                    </div>
                    <Typography style={{ color: "#fff" }} variant='inherit'>
                      {/* Change Audio Device */}
                      {localizedObject["AUDIO_SETTING"]}
                    </Typography>
                  </MenuItem>

                  {!isAudioOnly && ShowVirtualBackgroundSetting && (
                    <Tooltip
                      title={window.chrome ? "" : "This feature is only supported in Chrome"}
                      placement='left'
                      arrow
                    >
                      <MenuItem
                        id={"vbDilogButton"}
                        onClick={() => {
                          if (window.chrome) {
                            virtualBackgroundClicked();
                            // setIsBackgroundModalVisible(true);
                            handleSettingClose();
                          }
                        }}
                      >
                        <div className={classes.menuIconContainer} style={{ width: 25 }}>
                          <img
                            src={icon_virtual_background}
                            alt='hello'
                            style={{ height: 25, width: 23 }}
                          />
                        </div>
                        <Typography style={{ color: "#fff" }} variant='inherit'>
                          {/* Change Video Device
                           */}

                          {localizedObject["VIRTUAL_BACKGROUND"]}
                        </Typography>
                        {!window.chrome && (
                          <WarningIcon
                            style={{
                              aspectRatio: 1,
                              height: 15,
                              width: 30,
                              color: "red",
                              alignSelf: "center",
                            }}
                          />
                        )}
                      </MenuItem>
                    </Tooltip>
                  )}
                  {!isAudioOnly && (
                    <MenuItem
                      data-testid={"cc-videoSettingButton"}
                      onClick={() => {
                        setChangeVideoDeviceModel(true);
                        handleSettingClose();
                      }}
                    >
                      <div className={classes.menuIconContainer}>
                        <FlipCameraIosIcon className={classes.iconColor} stroke='#fff' />
                      </div>
                      <Typography style={{ color: "#fff" }} variant='inherit'>
                        {/* Change Video Device
                         */}

                        {localizedObject["VIDEO_SETTING"]}
                      </Typography>
                    </MenuItem>
                  )}

                  <Hidden smUp>
                    <MenuItem
                      onClick={() => {
                        handleSettingClose();
                        peopleClicked();
                      }}
                    >
                      <div className={classes.menuIconContainer}>
                        <PeopleOutlineIcon style={{ color: "#fff" }} fontSize='medium' />
                      </div>
                      <Typography style={{ color: "#fff" }} variant='inherit'>
                        {localizedObject["USER_LIST"]}
                      </Typography>
                    </MenuItem>
                  </Hidden>
                </MenuList>
              </ClickAwayListener>
            </Card>
          </Grow>
        )}
      </Popper>
      {layoutDialog()}
      {audioDevicesModel()}
      {videoDevicesModel()}
      {virtualBackgroundModel()}
    </div>
  );
};
export default SettingsButton;
SettingsButton.defaultProps = {
  showFullScreen: false,
  showChangeModeButton: false,
  changeMode: () => {},
  mode: "SIDEBAR",
  activeInputDevices: "",
  activeOutputDevices: "",
  changeAudioSourceClicked: () => {},
  changeVideoSourceClicked: () => {},
  changeAudioOutputDeviceClicked: () => {},
  audioOutPutDevices: [],
  audioDevices: [],
  videoDevices: [],
  activeVideoDevice: "",
  peopleClicked: () => {},
  setNoOfUsersInTile: () => {},
  noOfUsersInTile: 4,
  localizedObject: {},
};

SettingsButton.propTypes = {
  showFullScreen: PropTypes.bool,
  showChangeModeButton: PropTypes.bool,
  changeMode: PropTypes.func,
  mode: PropTypes.string,
  activeInputDevices: PropTypes.string,
  activeOutputDevices: PropTypes.string,
  changeAudioSourceClicked: PropTypes.func,
  changeVideoSourceClicked: PropTypes.func,
  changeAudioOutputDeviceClicked: PropTypes.func,
  audioOutPutDevices: PropTypes.arrayOf(PropTypes.object),
  audioDevices: PropTypes.arrayOf(PropTypes.object),
  videoDevices: PropTypes.arrayOf(PropTypes.object),
  activeVideoDevice: PropTypes.string,
  peopleClicked: PropTypes.func,
  setNoOfUsersInTile: PropTypes.func,
  noOfUsersInTile: PropTypes.number,
  localizedObject: PropTypes.object,
};
